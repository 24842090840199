import "../legal.css";

import * as React from "react";
import { useTranslation, Trans } from "react-i18next";
import { Link } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
// import { Parallax } from "react-scroll-parallax";

import Layout from "../../../components/layout/Layout";
import Seo from "../../../components/seo";
import SectionLanding from "../../../components/section/sectionLanding";
import Mask3 from "../../../svg/masks/mask3.svg";

import Subrayado1 from "../../../svg/subrayados/subrayado01.svg";
import Subrayado3 from "../../../svg/subrayados/subrayado03.svg";
import Subrayado4 from "../../../svg/subrayados/subrayado04.svg";

export default function IndexPage() {
	const { t } = useTranslation();
	return (
		<Layout footerText={t("¿A dónde volverías tú?")}>
			<Seo title={t("Aviso legal")} />
			<StaticImage
				src={`../../../images/contacto.jpg`}
				className="landing-bg"
				alt="Section background"
				layout="fullWidth"
			/>
			{/* <Parallax speed={-10} className="parallaxImg">
				<StaticImage
					src={`../../../images/contacto.jpg`}
					className="landing-bg"
					alt="Section background"
					layout="fullWidth"
				/>
			</Parallax> */}
			<Mask3 />

			<SectionLanding title={t("Preguntas frecuentes")} />

			<div className="legalBody invertColors" id="politicaRRSS">
				<h2>
					<Trans>Reservas</Trans>
				</h2>

				<h3>
					<Trans>¿Cómo hago mi reserva?</Trans>
				</h3>
				<p>
					<Trans i18nKey={"faq1"}>
						Nos alegra muchísimo que quieras vivir la experiencia Begin.
						<br />
						Para reservar simplemente accede al{" "}
						<Link to="/reserva">
							<b>apartado de reservas</b>
							<Subrayado3 />
						</Link>{" "}
						de nuestra web y selecciona el día, hora y número de comensales. En
						este apartado te aparecerá la disponibilidad real del restaurante 🌿
						<br />
						En el caso de no estar disponible el día que tenías en mente, puedes
						reservar otro día.
						<br />
						Si hubiera alguna cancelación, la mesa cancelada se liberaría
						automáticamente y se podría reservar desde la web como cualquier
						otra reserva. No tenemos lista de espera.
						<br />
						Además, te recordamos que muy pronto abriremos nuestra terraza
						exterior, por lo que el número de reservas aumentará :)
					</Trans>
				</p>

				<h3>
					<Trans>¿Cómo cancelo mi reserva?</Trans>
				</h3>
				<p>
					<Trans i18nKey="faq2">
						Si quieres cancelar una reserva recuerda que puedes hacerlo desde el
						correo o el sms que te llega al hacer la reserva.
						<br />
						Esperamos verte muy pronto volviendo al Origen 😌🌱
					</Trans>
				</p>

				<h3>
					<Trans>¿Cómo puedo modificar mi reserva?</Trans>
				</h3>
				<p>
					<Trans i18nKey="faq3">
						Si quieres cambiar una reserva de día u hora contáctanos indicando
						qué fecha y hora estaba hecha la reserva original, además del nombre
						de quién la hizo.
						<br />
						Por otro lado, necesitamos saber la fecha u hora (o ambas) de la
						nueva reserva que quieres. Intentaremos hacer lo posible, pero el
						cambio estará sujeto a disponibilidad.
					</Trans>
				</p>

				<h3>
					<Trans>¿Puedo ampliar mi reserva?</Trans>
				</h3>
				<p>
					<Trans i18nKey="faq4">
						Si quieres ampliar una reserva, contáctanos indicando qué fecha y
						hora estaba hecha la reserva original, además del nombre de quién la
						hizo.
						<br />
						Por otro lado, necesitamos saber el nuevo número de comensales.
						Intentaremos hacer lo posible, pero el cambio estará sujeto a
						disponibilidad.
					</Trans>
				</p>

				<h3>
					<Trans>Tengo dudas acerca de mi reserva</Trans>
				</h3>
				<p>
					<Trans>
						Simplemente contáctanos a través del chat (abajo a la derecha) y
						cuéntanos tus dudas o comentarios, estaremos encantados de poder
						ayudarte.
					</Trans>
				</p>

				<h2>
					<Trans>Otros</Trans>
				</h2>

				{/* <h3>
          <Trans>Dirección</Trans>
        </h3>
        <p>
          <Trans>
            Nuestra dirección es Av. Cortes Valencianas 50, nuestro local hace
            esquina con Carrer L’Horta Nord.{" "}
            <a
              href="https://goo.gl/maps/E3rr8uMRYGQywuqR7"
              target="_blank"
              rel="noopener noreferrer"
            >
              <b>Abrir en Google Maps.</b>
              <Subrayado4 />
            </a>
          </Trans>
        </p>

        <h3>
          <Trans>Horario</Trans>
        </h3>
        <p>
          <Trans>
            Nuestro horario es de Domingo a Jueves de 12:30 a 23:15
            (ininterrumpido) y de Viernes a Sábado de 12:20 a 23:45
            (ininterrumpido).
          </Trans>
        </p> */}

				<h3>
					<Trans>Alérgenos</Trans>
				</h3>
				<p>
					<Trans i18nKey={"FAQAllergens"}>
						En el restaurante contamos con toda la lista de alérgenos.
						<br />
						{/* Respecto a los platos sin gluten contamos con parte de nuestra carta
            gluten-free, además hay platos que se pueden modificar.
            <br /> */}
						Por otro lado, contamos con opciones veganas y vegetarianas.
						<br />
						Podéis consultar los platos en el apartado{" "}
						<Link to="/carta">
							<b>carta</b>
							<Subrayado1 />
						</Link>{" "}
						de nuestra web.
					</Trans>
				</p>

				<h3>
					<Trans>Colaboración</Trans>
				</h3>
				<p>
					<Trans>
						Para colaborar con nosotros, contáctanos a través de{" "}
						<Link to="/contacto/">
							<b> este formulario</b>
							<Subrayado3 />
						</Link>
						.
					</Trans>
				</p>

				<h3>
					<Trans>Mascotas</Trans>
				</h3>
				<p>
					<Trans>
						De momento no podéis traer a vuestros amigos peludos al restaurante,
						salvo en terraza exterior donde son más que bienvenidos 🐶
					</Trans>
				</p>

				<h3>
					<Trans>Otro</Trans>
				</h3>
				<p>
					<Trans>
						Puedes consultarnos cualquier otra duda o comentario a través del{" "}
						<Link to="/contacto/">
							<b> formulario de contacto</b>
							<Subrayado4 />
						</Link>{" "}
						o de nuestro chat (abajo a la derecha).
					</Trans>
				</p>
			</div>
		</Layout>
	);
}

export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`;
